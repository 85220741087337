import {setCookie} from 'nookies'
import {noop} from 'lodash-es'
import {useRouter} from '_app/hooks/useRouter'
import GenerateCustomerTokenMutation from '_app/graphql/mutations/auth/GenerateCustomerTokenMutation'
import {APP_ROUTES} from '_app/routes'
import {useMutation} from '_app/hooks/useMutation'
import {cart, isAuthenticatedVar} from '_app/cache'
import {AssignCustomerToGuestCartDocument} from '../graphql/generated/graphql'
import {initializeApollo} from '../../helpers/ApolloHelper/ApolloHelper'
import CartHelper from '../../helpers/CartHelper/CartHelper'
import {GetCartDocumentSmall} from '../graphql/queries/cart/GetCartDocumentSmall'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'
import ConfigurationHelper from '../../helpers/ConfigurationHelper/ConfigurationHelper'

interface GenerateCustomerTokenProps {
  email: string;
  password: string;
}

interface UseLogin {
  generateCustomerToken({
    variables,
  }: {
    variables: GenerateCustomerTokenProps;
    refetchQueries: any[];
  }): void;
}

const useLogin = ({
  redirectUrl = APP_ROUTES.PROFILE.DEFAULT,
  onSuccess = noop,
}: {
  redirectUrl?: string;
  onSuccess?: VoidFunction;
}): UseLogin => {
  const router = useRouter()
  const { locale } = router
  const apolloClient = initializeApollo(null, { locale })
  const noMergeCarts = ConfigurationHelper.getConfiguration('no_merge_carts', router) === '1'
  const { mutate: generateCustomerToken } = useMutation({
    mutation: GenerateCustomerTokenMutation,
    onCompleted: async ({ generateCustomerToken }) => {
      setCookie(null, 'customerToken', generateCustomerToken.token, { path: '/' })
      isAuthenticatedVar(!!generateCustomerToken.token)
      let cartId = CookiesHelper.get('cartId')
      const cartIdExist = cartId?.length > 0
      if (cartIdExist && !noMergeCarts) {
        const { data } = await apolloClient.mutate<{
          assignCustomerToGuestCart: { id: string };
        }>({
          mutation: AssignCustomerToGuestCartDocument,
          variables: {
            cartId: cartId,
          },
        })
        cartId = data?.assignCustomerToGuestCart?.id || cartId
        if (cartIdExist) {
          await CartHelper.setCartId(cartId)
        }
        if (cartIdExist) {
          await apolloClient.query({
            query: GetCartDocumentSmall,
            variables: { cartId },
          }).then((res) => {
            if (res) {
              cart(res?.data?.GetCart)
            }
          })
        }
      } else {
        await CartHelper.createEmptyCart(apolloClient, null, false, true)
      }
      await window.open(`/${locale}/${redirectUrl}`, '_self')
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    },
  })

  return {
    generateCustomerToken,
  }
}

export default useLogin
