import { gql } from '@apollo/client'

const GENERATE_CUSTOMER_TOKEN = gql`
  mutation GenerateCustomerTokenMutation($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`

export default GENERATE_CUSTOMER_TOKEN
