import {Center, Divider, Text} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'
import {useEffect, useState} from 'react'
import {getCsrfToken, getProviders} from 'next-auth/react'
import {addApolloState} from 'helpers/ApolloHelper/ApolloHelper'
import withAppInit from '_app/hoc/withAppInit'
import {Context} from '_app/types/context'
import LoginForm from 'modules/login/LoginForm'
import LinkButton from '_app/components/Buttons/LinkButton'
import {APP_ROUTES} from '_app/routes'
import ImageWithForm from '_app/components/ImageWithForm'
import getServerSession from '_app/utils/getServerSession'
import {useRouter} from '../_app/hooks/useRouter'
import I18nHelper from '../helpers/I18nHelper/I18nHelper'

const Login = () => {
    const {t} = useTranslation('auth')
    const [providers, setProviders]: any = useState({})
    const router = useRouter()
    const { locale } = router
    const getSocialLogin = async () => {
        const tempProviders = await getProviders()
        setProviders(tempProviders)
    }
    useEffect(() => {
        getSocialLogin()
    }, [])
    return (
        <ImageWithForm>
            <LoginForm
                providers={providers}
                redirectUrl={APP_ROUTES.PROFILE.DEFAULT}
            />
            <Center width="100%" py={4}>
                <LinkButton href={`/${locale}${APP_ROUTES.PASSWORD.RESET}`}>
                    {t('FORGOT_PASSWORD', {ns: 'common'})}
                </LinkButton>
            </Center>
            <Divider/>
            <Center my={4} flexWrap="wrap">
                <Text mr={2} fontWeight="light">
                    {t('NO_ACCOUNT_YET', {ns: 'common'})}
                </Text>
                <LinkButton href={`/${locale}/signup.html`}>
                    {t('REGISTER')}
                </LinkButton>
            </Center>
            <Divider/>
        </ImageWithForm>
    )
}

export const getServerSideProps = withAppInit(async (ctx: Context) => {
    const {res} = ctx
    const session = await getServerSession(ctx)
    if (session && res && session.accessToken) {
        res.writeHead(302, {
            Location: '/profile/customer',
        })
        res.end()
        return
    }
    return addApolloState(ctx.apolloClient, {
        props: {
            csrfToken: await getCsrfToken(ctx) ?? null,
            ...(await I18nHelper.getI18nProps(ctx, ['auth'])),
        },
    })
})

export default Login
