import { yupResolver } from '@hookform/resolvers/yup'
import {FC, useState} from 'react'
import { useTranslation } from 'next-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import Input from '_app/components/Form/Input'
import Button from '_app/components/Buttons/Button'
import useLogin from '_app/hooks/useLogin'
import { GetCustomerDocument } from '_app/graphql/generated/graphql'
import LoginProviders from './LoginProviders'
import {CircularProgress} from '@chakra-ui/react'

interface GenerateCustomerTokenProps {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required('errors.string.email'),
  password: yup.string().required('errors.string.password'),
})

interface LoginFormProps {
  providers?: any[];
  redirectUrl?: string;
  onSuccess?: VoidFunction;
}

const LoginForm: FC<LoginFormProps> = ({
  providers,
  redirectUrl,
  onSuccess,
}) => {
  const { t } = useTranslation('auth')
  const [locked, setLocked] = useState(false)
  const methods = useForm<GenerateCustomerTokenProps>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const { generateCustomerToken } = useLogin({
    redirectUrl,
    onSuccess: () => onSuccess?.(),
  })

  const onSubmit = handleSubmit(async data => {
    setLocked(true)
    await generateCustomerToken({
      variables: data,
      refetchQueries: [GetCustomerDocument, 'GetCart', 'GetListingProducts'],
    })
    setLocked(false)
  })


  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Input
          placeholder={t('ENTER_EMAIL_PLACEHOLDER', { ns: 'common' })}
          name="email"
          autoComplete="username"
          label={t('ENTER_EMAIL_LABEL', { ns: 'common' })}
        />
        <Input
          placeholder={t('ENTER_PASSWORD_PLACEHOLDER', { ns: 'common' })}
          name="password"
          type="password"
          autoComplete="current-password"
          label={t('ENTER_PASSWORD_LABEL', { ns: 'common' })}
        />
        <Button width="full" type="submit" isLoading={isSubmitting} disabled={locked}>
          {t('LOG_IN', { ns: 'common' })}
          {
              locked && (
                  <CircularProgress sx={{marginLeft: '20px', transform: 'scale(.7)'}} isIndeterminate/>
              )
          }
        </Button>

        {/* {providers && (*/}
        {/*  <LoginProviders*/}
        {/*    providers={providers}*/}
        {/*    redirectUrl={redirectUrl}*/}
        {/*    providerNamePrefix={`${t('LOG_IN_THROUGH')} `}*/}
        {/*  />*/}
        {/* )}*/}
      </form>
    </FormProvider>
  )
}

export default LoginForm
